import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import * as types from '../store/mutation-types'

Vue.use(VueRouter);

const router = new VueRouter({
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  routes:
  [
    {
      path: '/',
      name: 'home',
      component: () => import('../views/IndexView.vue')
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('../views/AboutView.vue')
    },
    {
      path: '/agree',
      name: 'agree',
      component: () => import('../views/AgreeView')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../views/LoginView')
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('../views/RegisterView')
    },
    {
      path: '/detail',
      name: 'detail',
      component: () => import('../views/DetailView')
    },
    {
      path: '/account',
      name: '账户管理',
      redirect: '/account/info',
      component: () => import('@/views/SubView'),
      children: [
        {
          path: 'info',
          name: '个人信息',
          component: () => import('@/views/Account/InfoView')
        },
        {
          path: 'password',
          name: '密码管理',
          component: () => import('@/views/Account/PasswordView')
        }
      ]
    }
  ]
});

router.beforeEach((to, from, next) => {
  let matched = to.matched
  if (matched.length >= 2) {
    // 更新面包屑
    store.commit(types.UPDATE_BREADCRUMB, {
      second: {
        title: matched[0].name,
        url: matched[0].path
      },
      third: {
        title: matched[1].name,
        url: matched[1].path
      }
    })

    // 更新子菜单
    let routes = router.options.routes
    let route = routes.filter(r => {
      return r.path === matched[0].path
    })[0].children
    if (route) {
      let submenu = []
      route.forEach(r => {
        submenu.push({
          title: r.name,
          url: matched[0].path + '/' + r.path,
          second: r.second
        })
      })
      store.commit(types.UPDATE_SUBMENU, submenu)
    }
  }

  next()
})

export default router;
