<template>
  <div class="tab panel_tab">
    <span v-for="(item, index) in data"
          :key="`tab_${index}`"
          class="tab_list"
          :class="{'tab_selected': flag === item.val}"
          @click= "toggleTab(item.val)">
      {{item.name}}<i></i>
    </span>
  </div>
</template>
<script>

export default {
  name: 'tabMenu',
  data () {
    return {
      flag: ''
    }
  },
  props: {
    data: {
      default: []
    },
    defalut: {
      default: ''
    },
    tabType: {
      type: String
    }
  },
  created () {
    this.flag = this.defalut
  },
  methods: {
    toggleTab (flag) {
      this.flag = flag
      this.$emit('getActive', this.flag)
    }
  }

}
</script>
<style lang="scss">
  .tab{
    &_list{
      display: inline-block;
      height: 45px;
      font-size: 19px;
      color: #999;
      cursor: pointer;
      padding: 0 10px;
    }
    &_selected{
      color: #409EFF;
      position: relative;
      & >i{
        display: inline-block;
        width:100%;
        height:3px;
        background:rgba(64,158,255,1);
        position: absolute;
        left: 0;
        bottom: -1px;
      }
    }
  }
</style>
