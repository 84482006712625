// 面包屑
export const UPDATE_BREADCRUMB = 'UPDATE_BREADCRUMB'
// 侧边栏
export const UPDATE_SUBMENU = 'UPDATE_SUBMENU'

// 获取渠道信息
export const GET_AGENTINFO_SUCCESS = 'GET_AGENTINFO_SUCCESS'
export const GET_AGENTINFO_FAILED = 'GET_AGENTINFO_FAILED'

// 获取公司信息
export const GET_BASIC_SUCCESS = 'GET_BASIC_SUCCESS'
export const GET_BASIC_FAILED = 'GET_BASIC_FAILED'

// 获取bannerImg
export const GET_BANNER_IMG_SUCCESS = 'GET_BANNER_IMG_SUCCESS'
export const GET_BANNER_IMG_FAILED = 'GET_BANNER_IMG_FAILED'

// 获取项目列表
export const GET_PROJECT_LIST_SUCCESS = 'GET_PROJECT_LIST_SUCCESS'
export const GET_PROJECT_LIST_FAILED = 'GET_PROJECT_LIST_FAILED'

// 获取项目详情
export const GET_PROJECT_DETAIL_SUCCESS = 'GET_PROJECT_DETAIL_SUCCESS'
export const GET_PROJECT_DETAIL_FAILED = 'GET_PROJECT_DETAIL_FAILED'

// 用户协议
export const GET_AGREE_SUCCESS = 'GET_AGREE_SUCCESS'
export const GET_AGREE_FAILED = 'GET_AGREE_FAILED'
