import Vue from 'vue'
import * as api from '../../api'
import * as types from '../mutation-types'

const state = {
  agenInfo: {
    name: '',
    nicename: '',
    mobile: '',
    email: '',
    site_name: ''
  }
}

const getters = {
  agenInfo: state => state.agenInfo
}

const mutations = {
  [types.GET_AGENTINFO_SUCCESS] (state, { data }) {
    Vue.set(state, 'agenInfo', data)
  },
  [types.GET_AGENTINFO_FAILED] (state) {
    Vue.set(state, 'agenInfo', {})
  }
}

const actions = {
  getAgentInfo ({ commit }, obj) {
    api.getAgentInfo(obj, data => {
      console.log(data)
      commit(types.GET_AGENTINFO_SUCCESS, data)
    }, () => {
      commit(types.GET_AGENTINFO_FAILED)
    })
  },
  getAgentLogout ({ commit }) {
    api.getAgentLogout(data => {
      console.log(data)
      commit(types.GET_AGENTINFO_SUCCESS, data)
    }, () => {
      commit(types.GET_AGENTINFO_FAILED)
    })
  },
  getEditPassword ({ commit }, obj) {
    api.getEditPassword(obj, data => {
      commit(types.GET_AGENTINFO_SUCCESS, data)
    }, () => {
      commit(types.GET_AGENTINFO_FAILED)
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
