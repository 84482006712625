<template>
  <div class="topbar innerheader">
    <div class="container clearfix">
      <div class="left">
        <a href="/" class="logo">{{basicData.title}}</a>
      </div>
      <div class="right">
        <div>
          <a href="/" :class="{ active: ''=== currentActive }" @click="addClass()">首页</a>
          <i></i>
          <a href="#/agree" :class="{ active: 'agree'=== currentActive }" @click="addClass('agree')">用户协议</a>
          <i></i>
          <a href="#/about" :class="{ active: 'about'=== currentActive }" @click="addClass('about')">关于我们</a>
        </div>
        <div class="agenInfo" v-if="!agenInfo.name">
          <a href="#/login" :class="{ active: 'login'=== currentActive }" @click="addClass('login')">登录</a>
          <i></i>
          <a href="#/register" :class="{ active: 'register'=== currentActive }" @click="addClass('register')">注册</a>
        </div>

        <div class="agenInfo" v-else>
          <div trigger="click">
            <a href="javascript:;" class="user-info el-dropdown-link">
              <span>欢迎您，{{agenInfo.name}}</span>
              <img class="down" src="../assets/images/tg_down.png" alt>
            </a>
            <div slot="dropdown">
              <div class="clearfix">
                <span @click="$router.push({path: '/account/info?id='+agenInfo.id})">个人中心</span>
              </div>
              <div class="clearfix">
                <span @click="loginout">退出登录</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'topBar',
  data () {
    return {
      currentActive: ''
    }
  },
  computed: {
    ...mapGetters(['agenInfo', 'basicData'])
  },
  created () {},
  methods: {
    ...mapActions(['getAgentLogout']),
    addClass (index) {
      this.currentActive = index
    },
    loginout () {
      this.getAgentLogout()
      this.$router.push({ path: '/login' })
    }
  }
}
</script>

<style lang="scss">
.topbar {
  font-size: 21px;
  line-height: 0px;
}
.innerheader {
  width: 100%;
  height: 90px;
  background: #FFFFFF;
  box-shadow: 0px 1px 5px 0px rgba(89, 89, 89, 0.22);
}

.innerheader .active {
  color: #4FC9EF;
}

.innerheader > div:first-child {
  padding: 15px 0;
  line-height: 49px;
}

.innerheader i {
  display: inline-block;
  width: 1px;
  height: 20px;
  background: #4FC9EF ;
  vertical-align: middle;
  margin: 0 20px;
}
.innerheader .left {
  float: left;
}
.innerheader .left .innerlogo {
  width: 146px;
  vertical-align: middle;
}

.innerheader .left .logo {
  width: 164px;
  height: 31px;
  font-size: 32px;
  font-family: SimHei;
  font-weight: bold;
  font-style: italic;
  color: #35B9E3;
  // box-shadow: 0px 1px 5px 0px rgba(89, 89, 89, 0.22);
}

.innerheader .right .email {
  width: 20px;
  height: 15px;
  position: relative;
  display: inline-block;
}

.innerheader .left .email img {
  width: 100%;
  height: 100%;
}
.innerheader .right {
  padding-left: 200px;
  // float: right;
}
.innerheader .right .topbar {
  float: left;
  width: auto;
}
.innerheader .right .agenInfo{
  font-size: 16px;
  line-height: 0px;
  margin-top: -30px;
  float: right;
  width: auto;
}
.innerheader .right .email b {
  position: absolute;
  right: -7px;
  top: 5px;
  width: 16px;
  height: 16px;
  background-color: #409eff;
  border-radius: 50%;
  text-align: center;
  line-height: 16px;
  color: #fff;
  font-size: 12px;
}

.innerheader .right .user-info .header {
  width: 45px;
  height: 45px;
  margin-right: 10px;
  vertical-align: middle;
}
.innerheader .right .user-info {
  text-decoration: none;
  height: 49px;
  display: block;
}
.innerheader .right .user-info span {
  color: #666666;
  margin-right: 10px;
}

.innerheader .right .user-info .down {
  width: 8px;
  vertical-align: text-top;
  margin-top: 4px;
}
</style>
