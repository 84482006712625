<template>
  <div class="footbar">
    <div class="text-center">
      <p>公司名称：{{ basicData.company }}&nbsp;|&nbsp;公司地址：{{ basicData.address }}</p>
      <p>{{ basicData.copyright }}</p>
      <p v-html="basicData.record_number">{{ basicData.record_number }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'footBar',
  computed: {
    ...mapGetters(['basicData'])
  }
}
</script>

<style lang="scss">
.footbar {
  width: 100%;
  font-size: 14px;
  font-family: Source Han Sans CN;
  color: #4C4C4C;
  background-color:#FFFFFF;
  height: auto;
  border: 1px solid #E5E5E5
}
</style>
