import Vue from 'vue'
import * as types from './mutation-types'

const mutations = {
  [types.UPDATE_SUBMENU] (state, data) {
    Vue.set(state, 'submenu', data)
  }
}

export default mutations
