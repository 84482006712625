import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import {
  Breadcrumb,
  BreadcrumbItem,
  MessageBox,
  Upload,
  Tooltip,
  Radio,
  RadioGroup,
  Table,
  TableColumn,
  Cascader,
  Loading,
  Pagination,
  Autocomplete,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Select,
  Option,
  InputNumber,
  Button,
  DatePicker,
  Input,
  Dialog,
  Form,
  FormItem
} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.config.productionTip = false;

Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Upload)
Vue.use(Tooltip)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Cascader)
Vue.use(Loading)
Vue.use(Pagination)
Vue.use(Autocomplete)
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Select)
Vue.use(Option)
Vue.use(InputNumber)
Vue.use(Button)
Vue.use(DatePicker)
Vue.use(Input)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(FormItem)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

Vue.prototype.showNotify = function (title, message, type, cbk) {
  MessageBox.alert(message, title, {
    confirmButtonText: '确定',
    type: type || 'success',
    callback: action => {
      cbk && cbk()
    }
  })
}
