<template>
  <div class="banner">
    <img :src="bannerData.image" :alt="bannerData.title">
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'bannerImg',
  computed: mapGetters([
    'bannerData'
  ])
}
</script>

<style lang="scss">
.banner{
  text-align: center;
}
</style>
