import axios from 'axios';
// import qs from 'qs'
import Vue from 'vue';

let loginFlag = true;

// 创建axios实例
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API,
  baseURL: window.apiUrl,
  timeout: 5000,
});

service.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
service.interceptors.request.use((config) => {
  if (config.method === 'post') {
    config.data = {
      ...config.data,
      // debug_agent_id: 90,
      format: 'json',
    };
  } else if (config.method === 'get') {
    config.params = {
      // debug_agent_id: 90,
      ...config.params,
      format: 'json',
    };
  }
  config.withCredentials = true;
  return config;
}, (error) => Promise.reject(error));
service.interceptors.response.use((response) => {
  if (loginFlag) {
    if (response.data.code === 1002) {
      loginFlag = false;
      Vue.prototype.showNotify('失败', '登录已过期，请重新登录！', 'error', () => {
        location.href = process.env.VUE_APP_BASE_API;
      });
    }
  }
  return response;
}, (error) => Promise.resolve(error.response));

export default service;
